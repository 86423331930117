<template>
    <el-main>
        <!-- <div class="authentication">
      <div style="margin-bottom: 20px">账号认证</div>
      <CdSteps :active="1">
        <Steps title="填写基本信息">
          <template #description>
            <div class="tips">请准备营业执照、法人身份证等材料，材料将用于主体认证以及微信支付商户号申请。</div>
          </template>
          <template #btn_box>
            <el-button type="primary" size="small">哈哈哈</el-button>
          </template>
        </Steps>
        <Steps title="签署协议">
          <template #description>
            <div class="tips">需要超级管理员签署《微信支付服务协议（电商平台二级商户）》，签署后获得的微信支付商户号将用于佣金结算。查看协议</div>
          </template>
        </Steps>
        <Steps title="绑定资金账户">
          <template #description>
            <div class="tips">绑定资金账户后，推广商品获得的佣金将结算汇款至绑定的银行卡。</div>
          </template>
        </Steps>
      </CdSteps>
    </div> -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="店铺商品" name="1">
                <el-form class="el-form-search" label-width="100px">
                    <el-form-item label="商品名称：">
                        <el-input size="small" v-model="name" placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label=" " label-width="25px">
                        <el-button size="small" @click="searchGoods" type="primary">搜索</el-button>
                        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
                    </el-form-item>
                </el-form>
                <!-- <Category></Category> -->
                <div class="goods-list">
                    <div class="goods-list-item" v-for="(item, index) in goodsList" :key="index">
                        <div class="img-block">
                            <img :src="item.goods_picture" alt="" />
                            <div :class="['selectd', item.is_select ? 'active' : '']" @click="selectGoods(item)">
                                <i v-show="item.is_select" class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="info-block" style="height: 115px">
                            <div>
                                <div class="info-header">
                                    <div class="title">{{ item.goods_name }}</div>
                                </div>
                                <div class="price-block">售价：¥{{ item.goods_price }}</div>
                            </div>
                            <div class="btn-block">
                                <el-button type="primary" size="small"
                                    @click="(showAddGoods = !0), (goods_id = item.id)">提交视频号商品
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <Paging class="paging" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData">
                </Paging>
            </el-tab-pane>
            <el-tab-pane label="视频号商品" name="2">
                <el-form class="el-form-search" label-width="100px">
                    <el-form-item label="商品名称：">
                        <el-input size="small" v-model="videoGoodsName" placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="审核状态：">
                        <el-select v-model="videoGoodsStatus" size="small" placeholder="请选择">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="已驳回" :value="-1"></el-option>
                            <el-option label="审核中" :value="0"></el-option>
                            <el-option label="已通过" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label=" " label-width="25px">
                        <el-button size="small" @click="searchVideoGoods" type="primary">搜索</el-button>
                        <el-button size="small" @click="searchClearVideo" type="text">清除搜索条件</el-button>
                    </el-form-item>
                </el-form>
                <div class="goods-list" v-if="videoGoodsList.length">
                    <div class="goods-list-item" v-for="(item, index) in videoGoodsList" :key="index">
                        <div class="img-block">
                            <img :src="item.head_img[0]" alt="" />

                            <div :class="['selectd', item.is_select ? 'active' : '']" @click="selectVidoeGoods(item)">
                                <i v-show="item.is_select" class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="info-block">
                            <div>
                                <div class="info-header">
                                    <div class="title">{{ item.title }}</div>
                                </div>
                                <div class="price-block" v-if="item.max_sale_price > item.min_sale_price">售价：¥{{
                                    item.min_sale_price }}
                                    - {{ item.max_sale_price }}</div>
                                <div class="price-block" v-else>售价：¥{{ item.min_sale_price }}</div>
                                <div class="commission-block" v-if="item.commission_ratio || item.is_union">
                                    <div class="current-commission">
                                        <span>赚</span>
                                        <span>¥{{ (item.min_sale_price * (item.commission_ratio / 100)) / 100 }}</span>
                                        <span>（佣金比例 {{ item.commission_ratio / 100 }}%）</span>
                                    </div>
                                    <div class="next-commission">
                                        <span>次日零点生效</span>
                                        <el-tooltip class="item" effect="dark"
                                            :content="`现赚${(item.min_sale_price * (item.next_commission_ratio / 100)) / 100}（${item.next_commission_ratio / 100}%）`"
                                            placement="top">
                                            <i class="el-icon-warning"></i>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-block">
                                <el-button v-if="item.status == 1" type="primary" size="small"
                                    @click="(showExtension = !0), (addGoodsExtension.commissionRatio = ''), (goods_id = item.out_product_id)">
                                    推广
                                </el-button>
                                <el-button v-if="item.status == 1" type="danger" size="small"
                                    @click="removeGoods(item)">删除</el-button>
                                <el-tag v-else-if="item.status == 0" type="warning">审核中</el-tag>
                                <div class="reject" v-else>
                                    <el-tag type="danger" style="margin-right: 10px">
                                        审核驳回
                                        <el-tooltip class="item" effect="dark" :content="item.audit_info" placement="top">
                                            <i class="el-icon-warning" style="font-size: 14px"></i>
                                        </el-tooltip>
                                    </el-tag>
                                    <el-button type="primary" size="small"
                                        @click="(showAddGoods = !0), (goods_id = item.out_product_id)">
                                        重新提审</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <No v-else></No>
                <Paging class="paging" :total="total_number1" :page="page1" :pageNum="rows1" @updatePageNum="updateData1">
                </Paging>
            </el-tab-pane>
        </el-tabs>
        <div class="total-select" v-if="selectedGoods.length || selectedVideoGoods.length">
            <div class="select-all-block">
                <div :class="['selectd', is_selectTotal ? 'active' : '']" @click="selectTotalGoods">
                    <i v-show="is_selectTotal" class="el-icon-check"></i>
                </div>
                <span class="all">全选本页</span>
                <span>已选{{ activeName == 1 ? selectedGoods.length : selectedVideoGoods.length }}</span>
            </div>
            <div class="btns">
                <el-button type="primary" @click="handleBatch">{{ activeName == 1 ? '提交视频号商品' : '推广' }}</el-button>
            </div>
        </div>
        <el-dialog title="提交视频号商品" :visible.sync="showAddGoods" width="600px">
            <el-form ref="form" :model="addVideoGoodsForm" :rules="rules" label-width="120px">
                <el-form-item label="视频号分类：" prop="third_cat_id">
                    <el-cascader v-model="addVideoGoodsForm.third_cat_id" :props="handelGoodsType()" style="width: 350px">
                    </el-cascader>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAddGoods = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmAddGoods">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="推广商品" :visible.sync="showExtension" width="600px">
            <el-form ref="form" :model="addGoodsExtension" :rules="rules1" label-width="120px">
                <el-form-item label="商品佣金比例：" prop="commissionRatio">
                    <el-input v-model="addGoodsExtension.commissionRatio" placeholder="请设置佣金比例要大于5%">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showExtension = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmGoodsExtension">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>
<script>
// import Category from './components/category.vue';
import Paging from '@/components/paging';
// import CdSteps from '@/components/Steps/index';
// import Steps from '@/components/Steps/stepsComponent.vue';
export default {
    components: {
        // Category,
        Paging,
        // CdSteps,
        // Steps,
    },
    data () {
        return {
            activeName: '1',
            name: '',
            page: 1,
            rows: 10,
            is_selectTotal: !1,
            total_number: 0,
            total_number1: 0,
            page1: 1,
            rows1: 10,
            goodsList: [],
            videoGoodsList: [],
            selectedGoods: [],
            selectedVideoGoods: [],
            showAddGoods: !1,
            showExtension: !1,
            videoGoodsName: '',
            videoGoodsStatus: '',
            goods_id: '',
            addVideoGoodsForm: {
                third_cat_id: [],
            },
            addGoodsExtension: {
                commissionRatio: '',
            },
            rules: {
                third_cat_id: {
                    type: 'array',
                    required: true,
                    message: '请选择视频号分类',
                    trigger: 'blur',
                },
            },
            rules1: {
                commissionRatio: {
                    required: true,
                    message: '请填写佣金比例',
                    trigger: 'blur',
                },
            },
        };
    },
    created () {
        this.getGoodsList();
        this.getVideoGoodsList();
    },
    methods: {
        handleClick () {
            this.selectedGoods = [];
            this.selectedVideoGoods = [];
            this.is_selectTotal = !1;
            this.goodsList.map(item => (item.is_select = !1));
            this.videoGoodsList.map(item => (item.is_select = !1));
        },
        updateData (val, status) {
            if (status == 0) {
                this.rows = val;
            } else {
                this.page = val;
            }
            this.getGoodsList();
        },
        updateData1 (val, status) {
            if (status == 0) {
                this.rows1 = val;
            } else {
                this.page1 = val;
            }
            this.getVideoGoodsList();
        },
        searchGoods () {
            this.rows = 10;
            this.page = 1;
            this.getGoodsList();
        },
        searchClear () {
            this.rows = 10;
            this.page = 1;
            this.name = '';
            this.getGoodsList();
        },
        searchVideoGoods () {
            this.rows1 = 10;
            this.page1 = 1;
            this.getVideoGoodsList();
        },
        searchClearVideo () {
            this.rows1 = 10;
            this.page1 = 1;
            this.videoGoodsName = '';
            this.videoGoodsStatus = '';
            this.getVideoGoodsList();
        },
        handelGoodsType () {
            let that = this;
            return {
                lazy: true,
                lazyLoad (node, resolve) {
                    const { level, data } = node;
                    let params = {
                        level: level + 1,
                    };
                    if (params.level > 1) {
                        params.id = data.superior_id;
                    }
                    that.$axios.post(that.$api.set.videoNumber.getWxShopCat, params).then(res => {
                        if (res.code == 0) {
                            let list = res.result.list;
                            const nodes = list.map(item => ({
                                value: params.level == 1 ? item.first_cat_id : params.level == 2 ? item.second_cat_id : item.third_cat_id,
                                superior_id: params.level == 1 ? item.first_cat_id : params.level == 2 ? item.second_cat_id : item.third_cat_id,
                                label: params.level == 1 ? item.first_cat_name : params.level == 2 ? item.second_cat_name : item.third_cat_name,
                                leaf: level >= 2,
                            }));
                            resolve(nodes);
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                },
            };
        },
        getGoodsList () {
            this.$axios
                .post(this.$api.distribution.goodsList, {
                    page: this.page,
                    rows: this.rows,
                    goods_name: this.name,
                })
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.list;
                        list.map(item => {
                            item.is_select = !1;
                        });
                        this.goodsList = list;
                        this.total_number = res.result.total_number;
                        console.log(this.total_number);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getVideoGoodsList () {
            this.$axios
                .post(this.$api.set.videoNumber.getWxShopGoods, {
                    page: this.page1,
                    rows: this.rows1,
                    title: this.videoGoodsName,
                    status: this.videoGoodsStatus,
                })
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.list;
                        list.map(item => {
                            item.head_img = JSON.parse(item.head_img);
                            item.is_select = !1;
                        });
                        this.videoGoodsList = list;
                    }
                });
        },
        selectGoods (row) {
            row.is_select = !row.is_select;
            this.goods_id = '';
            this.selectedGoods = this.goodsList.filter(item => item.is_select);
        },
        selectVidoeGoods (row) {
            row.is_select = !row.is_select;
            this.goods_id = '';
            this.selectedVideoGoods = this.videoGoodsList.filter(item => item.is_select);
        },
        selectTotalGoods () {
            this.is_selectTotal = !this.is_selectTotal;
            this.goods_id = '';
            if (this.activeName == 1) {
                this.goodsList.map(item => {
                    this.is_selectTotal ? (item.is_select = !0) : (item.is_select = !1);
                    this.is_selectTotal ? (this.selectedGoods = this.goodsList) : (this.selectedGoods = []);
                });
            } else {
                this.videoGoodsList.map(item => {
                    this.is_selectTotal ? (item.is_select = !0) : (item.is_select = !1);
                    this.is_selectTotal ? (this.selectedVideoGoods = this.videoGoodsList) : (this.selectedVideoGoods = []);
                });
            }
        },
        handleBatch () {
            this.activeName == 1 ? (this.showAddGoods = !0) : (this.showExtension = !0);
        },
        removeGoods (row) {
            console.log(row);
            this.$confirm('请确认是否删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.set.videoNumber.delGoods, {
                        goods_id: row.out_product_id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getVideoGoodsList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        comfirmAddGoods () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let goods_id = [];
                    if (this.goods_id) {
                        goods_id.push(this.goods_id);
                    } else {
                        this.selectedGoods.map(item => goods_id.push(item.id));
                    }
                    this.$axios
                        .post(this.activeName == 1 ? this.$api.set.videoNumber.addWxShopGoods : this.$api.set.videoNumber.updateSpu, {
                            goods_id: this.activeName == 1 ? goods_id : this.goods_id,
                            third_cat_id: this.addVideoGoodsForm.third_cat_id[this.addVideoGoodsForm.third_cat_id.length - 1],
                        })
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success(this.activeName == 1 ? '添加成功' : '已重新提审');
                                this.showAddGoods = !1;
                                if (this.activeName == 2) this.getVideoGoodsList();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                }
            });
        },
        comfirmGoodsExtension () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let goods_info = [];
                    if (this.goods_id) {
                        goods_info.push({
                            outProductId: this.goods_id,
                            commissionRatio: this.addGoodsExtension.commissionRatio * 100,
                        });
                    } else {
                        this.selectedVideoGoods.map(item => {
                            goods_info.push({
                                outProductId: item.out_product_id,
                                commissionRatio: this.addGoodsExtension.commissionRatio * 100,
                            });
                        });
                    }
                    this.$axios
                        .post(this.$api.set.videoNumber.set_commission, {
                            goods_info: goods_info,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                this.getVideoGoodsList();
                                this.$message.success('设置成功');
                                this.showExtension = !1;
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .total-select {
        position: fixed;
        left: 270px;
        bottom: 0;
        height: 80px;
        width: calc(100% - 285px);
        z-index: 2;
        background-color: #fff;
        box-shadow: 0px -3px 0 0 rgba(0, 0, 0, 0.02);
        padding: 20px 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .select-all-block {
            display: flex;
            align-items: center;

            .selectd {
                width: 20px;
                height: 20px;
                border: 1px solid #fff;
                border-radius: 50%;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.1);

                i {
                    color: #fff;
                }
            }

            .active {
                background: #409eff;
                border: 1px solid #409eff;
            }

            .all {
                margin: 0 10px;
            }
        }

        .btns {
            width: 250px;
            display: flex;
            justify-content: space-between;
        }
    }

    .authentication {
        max-width: 700px;

        .tips {
            font-size: 14px;
            color: #9a9a9a;
        }
    }

    .goods-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        font-size: 14px;

        .goods-list-item {
            width: 290px;
            margin: 0 20px 20px 0;

            .img-block {
                position: relative;
                height: 210px;

                img {
                    width: 100%;
                    height: 100%;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    object-fit: cover;
                }

                .selectd {
                    width: 20px;
                    height: 20px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(0, 0, 0, 0.2);

                    i {
                        color: #fff;
                    }
                }

                .active {
                    background: #409eff;
                    border: 1px solid #409eff;
                }

                .btn-block {
                    position: absolute;
                    z-index: 2;
                    background: #e5e5e5;
                    height: 50px;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 20px;
                }
            }

            .info-block {
                padding: 12px;
                height: 155px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background: rgba(247, 247, 247, 0.7);

                .info-header {
                    .title {
                        height: 40px;
                        line-height: 19px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }

                .commission-block {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 5px;

                    .current-commission {
                        display: flex;
                        align-items: center;

                        span:nth-child(1) {
                            font-size: 17px;
                            color: #ff6146;
                            margin-right: 6px;
                        }

                        span:nth-child(2) {
                            font-size: 17px;
                            color: #ff6146;
                        }

                        span:nth-child(3) {
                            color: rgba(0, 0, 0, 0.5);
                            font-size: 12px;
                        }
                    }

                    .next-commission {
                        font-size: 12px;
                        display: flex;
                        align-items: center;

                        span {
                            line-height: 20px;
                        }

                        i {
                            color: #409eff;
                            font-size: 18px;
                        }
                    }
                }

                .btn-block {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}</style>